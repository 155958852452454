import { styled } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";
import { Container as GridContainer } from "style/components/Page";
import { styleBodyM, styleHeadingL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("theme", {
    lightgray: "--background-default",
    blue: "--block-blue-default",
    cyan: "--block-cyan-default",
});

const textColor = theme("theme", {
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
});

const hoverColor = theme("theme", {
    lightgray: "--text-on-neutral-link",
    blue: "--text-on-blue-primary-alt",
    cyan: "--text-on-cyan-primary",
});

const borderColor = theme("theme", {
    lightgray: "--line-on-neutral-default",
    blue: "--line-on-blue-default",
    cyan: "--line-on-cyan-default",
});

export const HeadingArrow = styled(Icon)`
    color: var(${textColor});
    margin-left: 0.875rem;
    margin-right: 0.875rem;
    transition:
        margin-left 0.2s ease-in-out,
        margin-right 0.2s ease-in-out,
        color 0.2s ease-in-out;
`;

export const Heading = styled.div`
    ${styleHeadingL}
    color: var(${textColor});
    grid-area: heading;
    grid-column: col-start 1 / span 4;
    margin-bottom: 1.3125rem;
    margin-top: 0;
    transition: color 0.2s ease-in-out;

    span {
        color: var(${textColor});
        transition: color 0.2s ease-in-out;
    }

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
        margin-bottom: 1.75rem;
    }
`;

export const Text = styled.div`
    ${styleBodyM};
    color: var(${textColor});
    grid-area: text;
    grid-column: col-start 1 / span 4;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 10;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 7;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 1 / span 5;
    }
`;

export const Link = styled(NavLink)`
    ${columnGap};
    border-bottom: none;
    color: var(${textColor});
    display: grid;
    grid-template-areas: "heading" "text";
    grid-template-columns: repeat(4, [col-start] 1fr);

    &:hover {
        ${Heading} {
            color: var(${hoverColor});

            span {
                color: var(${hoverColor});
            }
        }

        ${HeadingArrow} {
            color: var(${hoverColor});
            margin-left: 1.75rem;
            margin-right: 0;
        }
    }

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Container = styled(GridContainer)`
    display: inline-flex;
    padding-bottom: 2.625rem;
    padding-top: 2.625rem;
    width: 100%;

    ${MQ.FROM_M} {
        padding-bottom: 3.5rem;
        padding-top: 3.5rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 4.375rem;
        padding-top: 4.375rem;
    }
`;

export const Wrapper = styled.div`
    align-items: center;
    background: var(${backgroundColor});
    background-clip: border-box;
    border-bottom: 1px solid var(${borderColor});
    border-top: 1px solid var(${borderColor});
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    position: relative;
    width: 100%;
`;
